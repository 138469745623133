import React from "react";

const LogoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...props} viewBox="0 0 330.06 216.88">
      <path
        fill="currentColor"
        d="M105.16,170.63H92.07a14.16,14.16,0,0,1-6.49-1.24q-2.37-1.24-3.55-5l-2.59-8.61a101.87,101.87,0,0,1-9,7.26,55.65,55.65,0,0,1-9.14,5.25,46.58,46.58,0,0,1-10,3.18,60.21,60.21,0,0,1-11.8,1.07,46.63,46.63,0,0,1-14.16-2.07,30.58,30.58,0,0,1-11.15-6.22,28.21,28.21,0,0,1-7.26-10.3,36.69,36.69,0,0,1-2.6-14.33,28.3,28.3,0,0,1,3.6-13.55q3.6-6.69,12-12.08T42.15,105q13.92-3.54,34.57-3.55V94.35q0-12.25-5.13-18.14T56.67,70.33A36.2,36.2,0,0,0,44.86,72a45.5,45.5,0,0,0-8.2,3.72q-3.48,2.05-6.43,3.71a13,13,0,0,1-6.49,1.66,8.44,8.44,0,0,1-5.07-1.54,12,12,0,0,1-3.42-3.77L9.94,66.43Q30.82,47.32,60.32,47.32A48.68,48.68,0,0,1,79.26,50.8a40.15,40.15,0,0,1,14.1,9.67,41.27,41.27,0,0,1,8.79,14.81,56.85,56.85,0,0,1,3,18.88ZM48.52,152.46a39.4,39.4,0,0,0,8.26-.82,31.17,31.17,0,0,0,7.14-2.44,38.11,38.11,0,0,0,6.49-4,57.89,57.89,0,0,0,6.31-5.64V119.3a118.24,118.24,0,0,0-21.29,1.6A56.41,56.41,0,0,0,41.68,125q-5.19,2.5-7.38,5.83a13,13,0,0,0-2.18,7.24q0,7.74,4.54,11.06T48.52,152.46Z"
      />
      <path
        fill="currentColor"
        d="M233.61,107.67c1.14,2.72,2.26,5.45,3.36,8.21q1.65-4.26,3.42-8.38t3.78-7.91l50.5-94.52a13.11,13.11,0,0,1,1.95-2.83A5.83,5.83,0,0,1,298.86.71a9.79,9.79,0,0,1,2.77-.59Q303.16,0,305.29,0h24V170.63h-28V60.42q0-3.08.18-6.73t.53-7.43L250.42,143a13.59,13.59,0,0,1-4.6,5.13,11.91,11.91,0,0,1-6.61,1.83h-4.37a11.88,11.88,0,0,1-6.6-1.83,13.7,13.7,0,0,1-4.61-5.13L171.36,45.9q.46,3.9.65,7.61t.18,6.91V170.63h-28V0h23.95q2.13,0,3.66.12a9.86,9.86,0,0,1,2.78.59,6.52,6.52,0,0,1,2.3,1.53,12.18,12.18,0,0,1,2,2.83l51,94.76C231.23,102.35,232.46,105,233.61,107.67Z"
      />
      <path
        fill="currentColor"
        d="M22.06,216.63H19.17a1.2,1.2,0,0,1-.8-.25,1.58,1.58,0,0,1-.46-.62L16,210.68H6.06l-1.92,5.08a1.52,1.52,0,0,1-.44.6,1.21,1.21,0,0,1-.8.27H0l9.14-23h3.79ZM15,208l-3.17-8.42a23.15,23.15,0,0,1-.79-2.48c-.11.5-.24,1-.38,1.39s-.27.79-.38,1.11L7.09,208Z"
      />
      <path fill="currentColor" d="M46.38,213.49v3.14H33.07v-23h3.75v19.9Z" />
      <path
        fill="currentColor"
        d="M72.3,213.6v3H57.92v-23H72.29v3H61.68v6.95h8.48v2.92H61.68v7.12Z"
      />
      <path
        fill="currentColor"
        d="M102.86,216.63H99.14a.92.92,0,0,1-.63-.21,1.64,1.64,0,0,1-.38-.46l-5.73-9.24c0,.13-.09.25-.14.37a1.54,1.54,0,0,1-.17.34L86.56,216a3.5,3.5,0,0,1-.39.46.8.8,0,0,1-.57.21H82.11l7.78-11.84-7.46-11.2h3.71a1.13,1.13,0,0,1,.59.12,1.16,1.16,0,0,1,.33.36l5.6,8.8c0-.13.1-.26.16-.39a3.72,3.72,0,0,1,.2-.4l5.22-8a1.2,1.2,0,0,1,.35-.41.79.79,0,0,1,.47-.13h3.56l-7.52,11Z"
      />
      <path
        fill="currentColor"
        d="M154.91,193.59v23h-3.3V200.84q0-.39,0-.84c0-.31,0-.61.07-.92l-7.41,13.68a1.41,1.41,0,0,1-1.33.84h-.52a1.39,1.39,0,0,1-1.33-.84L133.6,199c0,.31.06.62.07.93s0,.6,0,.87v15.79H130.4v-23h2.8a2.61,2.61,0,0,1,.77.09c.18,0,.34.23.49.53l7.39,13.39c.16.31.32.63.46,1s.27.65.39,1c.13-.35.27-.68.41-1s.3-.66.46-1l7.28-13.36c.15-.3.31-.48.49-.53a2.57,2.57,0,0,1,.77-.09Z"
      />
      <path
        fill="currentColor"
        d="M188,216.63h-2.89a1.18,1.18,0,0,1-.8-.25,1.6,1.6,0,0,1-.47-.62l-1.92-5.08H172l-1.92,5.08a1.46,1.46,0,0,1-.45.6,1.19,1.19,0,0,1-.8.27h-2.89l9.13-23h3.8ZM180.89,208l-3.16-8.42a23.15,23.15,0,0,1-.79-2.48c-.11.5-.24,1-.38,1.39s-.27.79-.39,1.11L173,208Z"
      />
      <path fill="currentColor" d="M202.75,216.63H199v-23h3.76Z" />
      <path
        fill="currentColor"
        d="M235.61,193.59v23h-1.9a1.45,1.45,0,0,1-1.31-.66l-12.66-16.25c0,.31.06.61.07.92s0,.58,0,.84v15.15h-3.3v-23h2l.4,0a1.4,1.4,0,0,1,.31.09,1,1,0,0,1,.28.19c.08.09.17.19.27.32l12.67,16.27c0-.33-.06-.65-.07-1s0-.62,0-.9v-15Z"
      />
      <path
        fill="currentColor"
        d="M268.29,205.28v9.12a13.29,13.29,0,0,1-8,2.48,14,14,0,0,1-5.07-.87,11.14,11.14,0,0,1-3.86-2.43,10.68,10.68,0,0,1-2.47-3.72,12.79,12.79,0,0,1-.86-4.75,13.36,13.36,0,0,1,.83-4.78,10.44,10.44,0,0,1,6.13-6.14,13.45,13.45,0,0,1,4.93-.86,13.86,13.86,0,0,1,2.58.22,12,12,0,0,1,2.22.6,9.77,9.77,0,0,1,1.89.93,10.49,10.49,0,0,1,1.58,1.21L267.12,198a1,1,0,0,1-.66.5,1.14,1.14,0,0,1-.86-.19l-1-.58a8.84,8.84,0,0,0-1.19-.57,9,9,0,0,0-1.55-.43,11.42,11.42,0,0,0-2.08-.17,8.61,8.61,0,0,0-3.29.6,7,7,0,0,0-2.5,1.72,7.47,7.47,0,0,0-1.6,2.69,10.43,10.43,0,0,0-.56,3.54,10.9,10.9,0,0,0,.59,3.69,7.71,7.71,0,0,0,1.68,2.75,7.2,7.2,0,0,0,2.63,1.72,9.68,9.68,0,0,0,3.45.59,9.78,9.78,0,0,0,2.54-.3,12.44,12.44,0,0,0,2.18-.85v-4.58h-3.22a.76.76,0,0,1-.57-.2.69.69,0,0,1-.21-.52v-2.13Z"
      />
      <path
        fill="currentColor"
        d="M302.68,205.11a12.94,12.94,0,0,1-.83,4.72,10.58,10.58,0,0,1-6,6.16,13,13,0,0,1-9.41,0,10.61,10.61,0,0,1-6-6.16,13.84,13.84,0,0,1,0-9.44,11,11,0,0,1,2.35-3.73,10.72,10.72,0,0,1,3.64-2.45,13,13,0,0,1,9.41,0,10.68,10.68,0,0,1,3.63,2.45,10.94,10.94,0,0,1,2.34,3.73A12.9,12.9,0,0,1,302.68,205.11Zm-3.82,0a11.35,11.35,0,0,0-.53-3.57,7.56,7.56,0,0,0-1.54-2.69,6.7,6.7,0,0,0-2.42-1.69,8.81,8.81,0,0,0-6.39,0,6.73,6.73,0,0,0-2.43,1.69,7.57,7.57,0,0,0-1.55,2.69,11.89,11.89,0,0,0,0,7.15,7.44,7.44,0,0,0,1.55,2.68,6.71,6.71,0,0,0,2.43,1.68,8.95,8.95,0,0,0,6.39,0,6.67,6.67,0,0,0,2.42-1.68,7.43,7.43,0,0,0,1.54-2.68A11.32,11.32,0,0,0,298.86,205.11Z"
      />
      <path
        fill="currentColor"
        d="M330.06,196.71h-7.18v19.92h-3.73V196.71H312v-3.12h18.11Z"
      />
    </svg>
  );
};

export default LogoIcon;
